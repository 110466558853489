import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const EmailListForm = () => {

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        addToMailchimp(email)
            .then((data) => {
                alert(data.result)
            })
            .catch((error) => {
                console.log('error => ', error)
            })
    };

    const handleEmailChange = (event) => {
        setEmail(event.currentTarget.value);
    };

    return (
        <form onSubmit={handleSubmit} style={styles.container}>
            <h3>Inscrivez vous à ma lettre de diffusion</h3>
            <div style={styles.wrapper}>
                <input
                    style={styles.input}
                    placeholder="Adresse Email"
                    name="email"
                    type="text"
                    onChange={handleEmailChange}
                />
                <button type="submit" style={styles.button}>S'inscrire</button>
            </div>
        </form>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: '#f2f2f2',
        color: '#2a2a2a',
        padding: 2,
        alignItems: 'center'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 5,
        justifyContent: 'space-between',
    },
    input: {
        paddingHorizontal: 5,
        width: 500
    },
    button: {
        display: 'inline-block',
        color: 'white',
        background: '#f4a233',
        paddingHorizontal: 5
    }
}

export default EmailListForm;